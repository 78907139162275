import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

import { Settings } from './layouts/settings';
import { useUserStore } from '@/common/hooks/UserStore';

const ApiData = lazy(() => import('./pages/apiData'));
const Company = lazy(() => import('./pages/company'));
const Modules = lazy(() => import('./pages/modules'));
const Drivers = lazy(
  () => import('../../../apps/nexpro/modules/registrations/pages/drivers'),
);
const Groups = lazy(
  () => import('../../../apps/nexpro/modules/registrations/pages/groups'),
);
const Vehicles = lazy(
  () => import('../../../apps/nexpro/modules/registrations/pages/vehicles'),
);
const User = lazy(() => import('./pages/user'));
const SettingsOptions = lazy(() => import('./pages/settingsOptions'));
const DriversPerformance = lazy(() => import('./pages/driversPerformance'));
const Cards = lazy(() => import('./pages/cards'));
const Subscriptions = lazy(() => import('./pages/subscriptions'));
const Accounts = lazy(() => import('./pages/accounts'));
const Account = lazy(() => import('./pages/account'));
const Authorized = lazy(() => import('./pages/authorized'));

function AccountElement() {
  const { userAccount } = useUserStore();

  if (userAccount?.Account?.isMain) {
    return <Accounts />;
  }
  return <Account />;
}

export const SettingsRoutes: RouteObject[] = [
  {
    element: <Settings />,
    children: [
      {
        path: links.settings.company,
        element: <Company />,
      },
      {
        path: links.settings.modules,
        element: <Modules />,
      },
      {
        path: links.settings.users,
        element: <User />,
      },
      {
        path: links.settings.apiData,
        element: <ApiData />,
      },
      {
        path: links.settings.drivers,
        element: <Drivers />,
      },
      {
        path: links.settings.groups,
        element: <Groups />,
      },
      {
        path: links.settings.subscriptions,
        element: <Subscriptions />,
      },
      {
        path: links.settings.cards,
        element: <Cards />,
      },
      {
        path: links.settings.vehicles,
        element: <Vehicles />,
      },
      {
        path: links.settings.settingsOptions,
        element: <SettingsOptions />,
      },
      {
        path: links.settings.driversPerformance,
        element: <DriversPerformance />,
      },
      {
        path: links.settings.account,
        element: <AccountElement />,
      },
      {
        path: links.settings.authorized,
        element: <Authorized />,
      },
    ],
  },
];
