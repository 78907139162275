import { cva } from 'class-variance-authority';

export const container = cva([
  'max-h-[calc(100vh-8rem)]',
  'sm:max-h-screen',
  'h-screen',
  'w-screen',
  'px-6',
  'sm:px-12',
  'pt-4',
  'sm:pt-8',
  'pb-6',
  'sm:pb-12',
  'flex',
  'flex-col',
  'gap-8',
]);

export const header = cva([
  'space-y-2',
  'relative',
  'pb-4',
  'sm:pb-8',
  'border-b-2',
  'border-neutral-200',
]);

export const titleContainer = cva([
  'flex',
  'gap-1',
  'sm:gap-4',
  'items-center',
]);

export const title = cva([
  'text-brand-800',
  'text-2xl',
  'sm:text-3xl',
  'font-semibold',
]);

export const icon = cva(['text-brand-pure', 'w-6', 'h-6']);

export const subTitle = cva([
  'text-neutral-700',
  'text-md',
  'sm:text-lg',
  'font-normal',
]);

export const nexpro = cva([
  'text-transparent',
  'bg-clip-text bg-brand-gradient',
]);

export const section = cva([
  'flex',
  'flex-col',
  'gap-6',
  'w-full',
  'overflow-x-auto',
]);

export const sectionHeader = cva(['flex', 'justify-between', 'items-baseline']);

export const sectionInfo = cva(['space-y-2', 'self-start']);

export const sectionTitle = cva([
  'text-neutral-900',
  'text-2xl',
  'font-semibold',
]);

export const sectionSubTitle = cva([
  'text-neutral-700',
  'text-md',
  'sm:text-lg',
  'font-light',
]);

export const list = cva([
  'grid',
  'grid-cols-1',
  'p-2',
  'sm:grid-cols-2',
  'md:grid-cols-3',
  'lg:grid-cols-4',
  '2xl:grid-cols-5',
  'justify-start',
  'gap-6',
  'overflow-x-auto',
  'pb-2',
]);

export const tipContainer = cva([
  'flex',
  'items-center',
  'justify-start',
  'gap-2',
  'sm:gap-4',
  'w-full',
]);

export const tip = cva([
  'text-neutral-600',
  'text-sm',
  'sm:text-base',
  'font-light',
]);

export const footer = cva(['flex', 'justify-center', 'w-full', 'mt-auto']);

export const link = cva([
  'flex',
  'items-center',
  'justify-center',
  'gap-2',
  'w-full',
  'text-brand-pure',
  'text-sm',
]);
