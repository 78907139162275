import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingFallback } from '@/common/components/LoadingFallback';

import { AccountSelectionModal } from './components/AccountSelectionModal';
import { AppSelectionModal } from './components/AppSelectionModal';
import { Sidebar } from './components/Sidebar';
import { container, content } from './styles';

export function Root() {
  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  return (
    <div className={container()}>
      <Sidebar />

      <div className={content()}>
        <Suspense fallback={<LoadingFallback />}>
          <Outlet />
        </Suspense>
      </div>

      <AppSelectionModal />

      <AccountSelectionModal />
    </div>
  );
}
