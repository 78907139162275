import { create } from 'zustand';

import { UserTypeEnum } from '@/apps/nexpro/services/geofences';

import { IDriverUser } from '../types/Driver';
import { IOwnerUser } from '../types/OwnerUser';
import { UserRoleEnum } from '../types/Role';
import {
  IGetUserInfo,
  IUserInfoPerson,
  IUserInfoUserAccount,
  IUserInfoUserCompany,
} from '../types/User';

type StoreAccountUserProps = {
  user: IGetUserInfo;
  account: IUserInfoUserAccount;
};

export type SaveUserAccountProps = {
  accountId: string;
  role: UserRoleEnum;
};

type StoreCompanyUserProps = {
  user: IGetUserInfo;
  company: IUserInfoUserCompany;
  driver: IDriverUser | null;
};

interface IUserStore {
  user: IGetUserInfo | null;
  person: IUserInfoPerson | null;
  personId: string | null;
  accountId: string | null;
  company: IUserInfoUserCompany | null;
  companyId: string | null;
  ownerId: string | null;
  owner: IOwnerUser | null;
  role: UserRoleEnum | null;
  companies: IUserInfoUserCompany[] | null;
  userAccounts: IUserInfoUserAccount[] | null;
  userAccount: IUserInfoUserAccount | null;
  driver: IDriverUser | null;
  storeAccountUser: (data: StoreAccountUserProps) => void;
  storeCompanyUser: (data: StoreCompanyUserProps) => void;
  saveUserAccount: (data: SaveUserAccountProps) => void;
}

export const useUserStore = create<IUserStore>(set => {
  const storeAccountUser = (data: StoreAccountUserProps) => {
    if (data.user.userType !== UserTypeEnum.ACCOUNT) return [];

    const { user, account } = data;
    set({
      user,
      person: user?.Person,
      personId: user?.personId,
      role: account.role,
      userAccounts: user?.UserAccount,
      accountId: account.accountId,
      company: null,
      companyId: null,
      driver: null,
    });

    return user.UserAccount;
  };

  const saveUserAccount = (data: SaveUserAccountProps) => {
    const { accountId, role } = data;

    set(state => ({
      accountId,
      role,
      userAccount: state?.userAccounts?.find(
        account => account?.accountId === accountId,
      ),
    }));
  };

  const storeCompanyUser = (data: StoreCompanyUserProps) => {
    const { user, company, driver } = data;

    const userAccounts = user?.UserCompany?.reduce((acc, companyAccount) => {
      if (companyAccount.companyId) {
        acc.push({
          Account: companyAccount.Company.Account,
          accountId: companyAccount.accountId,
          userId: user.id,
          role: companyAccount.role,
          status: user.status,
        });
      }

      return acc;
    }, [] as IUserInfoUserAccount[]);

    set({
      user,
      person: user?.Person,
      personId: user?.personId,
      role: company.role,
      company,
      companyId: company.companyId,
      userAccounts,
      userAccount: userAccounts?.find(
        account => account.accountId === company.accountId,
      ),
      ownerId: company.Company.ownerId,
      owner: company.Company.User,
      accountId: company.accountId,
      driver,
    });
  };

  return {
    user: null,
    person: null,
    personId: null,
    role: null,
    accountId: null,
    company: null,
    companyId: null,
    ownerId: null,
    userAccounts: null,
    userAccount: {} as IUserInfoUserAccount,
    owner: null,
    companies: null,
    driver: null,
    storeAccountUser,
    storeCompanyUser,
    saveUserAccount,
  };
});
