import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { ArrowLeft, LayoutGrid } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountSelectionCard } from '@/common/components/AccountSelectionCard';
import { Alert } from '@/common/components/Alert';
import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';
import { SaveUserAccountProps, useUserStore } from '@/common/hooks/UserStore';
import { IGetUserInfo } from '@/common/types/User';
import { setItemLocal } from '@/common/utils/localStorage';

import { useLogin } from '../../hooks/useLogin';
import { useUserAccountsFilter } from '../../hooks/useUserAccountsFilter';

import { FiltersDropDown } from './components/FiltersDropDown';
import * as style from './styles';

export default function AccountSelection() {
  const { t } = useTranslation(['login/accountSelection', 'common']);
  const { signOut } = useLogin();
  const navigate = useNavigate();
  const { saveUserAccount } = useUserStore();
  const { country } = useUserAccountsFilter();
  const { state } = useLocation();
  const { changeApp } = useAppStore();
  const user = state.user as IGetUserInfo;
  const userAccounts = user?.UserAccount;

  const handleLoadUserAccount = async (
    selectedAccount: SaveUserAccountProps,
  ) => {
    const { accountId, role } = selectedAccount;

    Cookies.set('accountId', accountId);
    Cookies.set('role', role);

    await flagsmith.setTrait('role', role);

    saveUserAccount({
      accountId,
      role,
    });

    setItemLocal('app', EnumAPPS.NEXPRO);
    changeApp(EnumAPPS.NEXPRO);
    navigate('/');
  };

  const accounts =
    userAccounts?.filter(account =>
      country ? account.Account.country === country : true,
    ) || [];

  return (
    <main className={style.container()}>
      <header className={style.header()}>
        <div className={style.titleContainer()}>
          <h1 className={style.title()}>{t('systemAccess')}</h1>

          <LayoutGrid className={style.icon()} />
        </div>

        <p className={style.subTitle()}>
          {t('selectAccount')}{' '}
          <strong className={style.nexpro()}>Nexpro</strong>.
        </p>
      </header>

      {accounts && accounts.length > 0 ? (
        <section className={style.section()}>
          <div className={style.sectionHeader()}>
            <div className={style.sectionInfo()}>
              <h2 className={style.sectionTitle()}>{t('accounts')}</h2>
              <p className={style.sectionSubTitle()}>
                {t('availableAccounts')}
              </p>
            </div>

            <FiltersDropDown userAccounts={userAccounts!} />
          </div>

          <ul className={style.list()}>
            {accounts?.map(userAccount => (
              <li key={userAccount.accountId}>
                <AccountSelectionCard
                  account={userAccount.Account}
                  onClick={() => handleLoadUserAccount(userAccount)}
                />
              </li>
            ))}
          </ul>
          <Alert
            message={t('accountChangeTip')}
            type="info"
            className="w-fit"
          />
        </section>
      ) : (
        <section className={style.section()}>
          <Alert message={t('emptyAccounts')} type="warning" />
        </section>
      )}

      <footer className={style.footer()}>
        <div className={style.link()}>
          <ArrowLeft size={16} />
          <button type="button" onClick={signOut}>
            {t('backToLogin')}
          </button>
        </div>
      </footer>
    </main>
  );
}
