import Cookies from 'js-cookie';
import { ChevronRight, LayoutGrid, LogOut, Settings } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useLogin } from '@/apps/login/hooks/useLogin';
import { UserTypeEnum } from '@/apps/nexpro/services/geofences';
import Avatar from '@/common/components/Avatar';
import { FeatureFlag } from '@/common/components/FeatureFlag';
import { Tooltip } from '@/common/components/Tooltip';
import { EnumAPPS } from '@/common/hooks/AppStore';
import { useCheckModuleIsAnAPP } from '@/common/hooks/useCheckModuleIsAnAPP';
import { useGetUserInfo } from '@/common/hooks/useGetUserInfo';
import { useRoleTranslation } from '@/common/hooks/useRoleTranslation';
import { useUserStore } from '@/common/hooks/UserStore';
import { IAppNavigation } from '@/common/types/AppNavigation';
import { UserRoleEnum } from '@/common/types/Role';
import { links } from '@/common/utils/links';
import { getItemLocal } from '@/common/utils/localStorage';

import { useCollapseSidebar } from '../..';
import { NavItem } from '../NavItem';

import * as style from './styles';

type ConfigProps = {
  setHideMenu?: (hideMenu: boolean) => void;
};

export function Config({ setHideMenu }: ConfigProps) {
  const { signOut } = useLogin();

  const { t } = useTranslation(['sidebar/fixedMenus', 'settings/menus']);
  const { pathname } = useLocation();

  const { user, isLoading } = useGetUserInfo();
  const {
    role,
    person,
    storeAccountUser,
    storeCompanyUser,
    company: companyUserStore,
    saveUserAccount,
    userAccount,
  } = useUserStore();

  const isAccount = user?.userType === UserTypeEnum.ACCOUNT;

  const roleTranslation = useRoleTranslation();
  const roleTranslated = roleTranslation[role || ''];
  const { collapsed } = useCollapseSidebar();
  const { check } = useCheckModuleIsAnAPP();
  const currentPath = pathname.split('/')[1];
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getLink = () => {
    if (isWideScreen) {
      return isAccount ? links.settings.users : links.settings.company;
    }
    return links.settings.settingsOptions;
  };

  const navigation: IAppNavigation = {
    key: 'settings',
    title: t('settings/menus:settings'),
    titleIcon: ChevronRight,
    icon: Settings,
    link: getLink(),
    activePaths: [
      links.settings.company,
      links.settings.modules,
      links.settings.users,
      links.settings.apiData,
      links.settings.drivers,
      links.settings.groups,
      links.settings.vehicles,
      links.settings.driversPerformance,
      links.settings.cards,
      links.settings.subscriptions,
      links.settings.account,
      links.settings.authorized,
    ],
  };

  useEffect(() => {
    if (user?.UserAccount) {
      const accountId = Cookies.get('accountId')!;
      const roleFromCookies = Cookies.get('role') as UserRoleEnum;
      storeAccountUser({ user, account: user.UserAccount[0] });

      saveUserAccount({
        accountId,
        role: roleFromCookies,
      });
    }

    if (user?.UserCompany) {
      const companyId = getItemLocal<string>('companyId', { parse: false });
      const company = user.UserCompany.find(c => c.companyId === companyId);
      const driver =
        user?.Driver && user?.Driver?.length > 0 ? user?.Driver[0] : null;
      if (company) storeCompanyUser({ user, company, driver });
    }
  }, [user, storeAccountUser, storeCompanyUser, saveUserAccount]);

  const userHasValidCompanies = Boolean(
    user?.UserCompany &&
      user?.UserCompany?.reduce((acc, curr) => {
        const hasValidModules = curr.Company.CompanyModule.filter(
          companyModule => check(companyModule.moduleKey as EnumAPPS),
        ).length;

        return acc + hasValidModules;
      }, 0),
  );

  return (
    <section className={style.container()}>
      <div className={style.horizontalDivider()} />

      <div className={style.content()}>
        {isLoading && (
          <div className={style.avatarSkeletonContainer()}>
            <div className={style.avatarSkeleton()} />
            <div className={style.textSkeletonContainer()}>
              <div className={style.nameSkeleton()} />
              <div className={style.roleSkeleton()} />
            </div>
          </div>
        )}

        {!isLoading && user && (
          <Tooltip message={user?.displayName} hideTooltip={!collapsed}>
            <Link
              to={
                isWideScreen
                  ? links.userProfile.profile
                  : links.userProfile.userProfileOptions
              }
              onClick={() => {
                if (setHideMenu) {
                  setHideMenu(true);
                }
              }}
              className={style.user({
                isSelected: currentPath === `user-profile`,
              })}
            >
              <Avatar
                url={user?.avatarUrl}
                name={person?.name || user?.displayName}
                size="small"
              />
              <div className={style.description()}>
                <span className={style.name()}>{user?.displayName}</span>
                <div>
                  <span className={style.role()}>{roleTranslated}</span>
                  <ChevronRight
                    size={12}
                    className={style.profileChevronRight()}
                  />
                </div>
              </div>
            </Link>
          </Tooltip>
        )}

        <FeatureFlag flag="sidebar_system_access_modal">
          {userHasValidCompanies && (
            <div>
              <Tooltip
                message={companyUserStore?.Company?.displayName}
                hideTooltip={!collapsed}
              >
                <button
                  data-hs-overlay="#hs-system-access-modal"
                  type="button"
                  onClick={() => {
                    if (setHideMenu) {
                      setHideMenu(true);
                    }
                  }}
                  className={style.link()}
                >
                  <LayoutGrid size={18} className={style.icon()} />
                  <div className={style.storesButtonTextContainer()}>
                    <Tooltip message={t('companies')}>
                      <span className={style.storesButtonText()}>
                        {companyUserStore?.Company?.displayName}
                      </span>
                    </Tooltip>

                    <ChevronRight
                      size={12}
                      className={style.storesButtonIcon()}
                    />
                  </div>
                </button>
              </Tooltip>
            </div>
          )}
        </FeatureFlag>

        {isAccount && user.UserAccount && (
          <Tooltip
            message={userAccount?.Account?.title}
            hideTooltip={!collapsed}
          >
            <button
              data-hs-overlay="#hs-account-selection-modal"
              type="button"
              onClick={() => {
                if (setHideMenu) {
                  setHideMenu(true);
                }
              }}
              className={style.link()}
            >
              <LayoutGrid size={18} className={style.icon()} />
              <div className={style.storesButtonTextContainer()}>
                <Tooltip message={t('accounts')}>
                  <span className={style.accountsButtonText()}>
                    {userAccount?.Account?.title}
                  </span>
                </Tooltip>
                <ChevronRight size={12} className={style.storesButtonIcon()} />
              </div>
            </button>
          </Tooltip>
        )}

        <nav className={style.content()}>
          <NavItem setHideMenu={setHideMenu} data={navigation} />
        </nav>

        <Tooltip message={t('logout')} hideTooltip={!collapsed}>
          <button type="button" className={style.button()} onClick={signOut}>
            <LogOut size={18} className={style.logoutIcon()} />
            <span className={style.buttonText()}>{t('logout')}</span>
          </button>
        </Tooltip>
      </div>
    </section>
  );
}
